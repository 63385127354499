<template>
  <v-card class="mx-auto" :loading="loading">
    <v-card-title class="text-h6 font-weight-regular justify-space-between">
      <span>{{ currentTitle }}</span>
    </v-card-title>

    <v-window touchless v-model="step">
      <v-window-item :value="1">
        <v-card-text>
          <v-subheader>Aanmeldcode</v-subheader>
          <v-text-field
            v-model="signupcode"
            ref="otpSignup"
            type="text"
            maxlength="4"
            @input="uppercaseInputSignup"
          ></v-text-field>

          <v-subheader>Postcode cijfers</v-subheader>
          <v-text-field
            v-model="postalcode"
            ref="otpPostal"
            maxlength="4"
            type="text"
            pattern="[0-9]*"
            inputmode="numeric"
            @input="uppercaseInputPostal"
          ></v-text-field>

          <transition
            :name="
              errormessage
                ? 'scroll-y-reverse-transition'
                : 'scroll-y-transition'
            "
          >
            <v-alert
              dense
              class="mt-4"
              outlined
              v-show="errormessage"
              type="error"
            >
              {{ errormessage }}
            </v-alert>
          </transition>
        </v-card-text>
      </v-window-item>

      <!--
    <v-window touchless v-model="step">
      <v-window-item :value="1">
        <v-card-text>
          <v-subheader>Aanmeldcode</v-subheader>
          <v-otp-input
            ref="otpSignup"
            id="signupField"
            length="4"
            v-model="signupcode"
            @input="uppercaseInputSignup"
            @finish="finishInputSignup"
          ></v-otp-input>

          <v-subheader>Postcode</v-subheader>
          <v-otp-input
            ref="otpPostal"
            id="postalField"
            length="6"
            v-model="postalcode"
            @input="uppercaseInputPostal"
            @finish="next"
          ></v-otp-input>

          <transition
            :name="
              errormessage
                ? 'scroll-y-reverse-transition'
                : 'scroll-y-transition'
            "
          >
            <v-alert
              dense
              class="mt-4"
              outlined
              v-show="errormessage"
              type="error"
            >
              {{ errormessage }}
            </v-alert>
          </transition>
        </v-card-text>
      </v-window-item>
      -->

      <v-window-item :value="2">
        <div class="pa-4">
          <v-card-text>
            <v-row class="mb-4">
              <v-chip-group
                class="mx-auto"
                v-model="chipGroupSelection"
                :color="chipGroupActiveColor"
              >
                <v-chip large filter filter-icon="mdi-close">Afmelden</v-chip>
                <v-chip large filter filter-icon="mdi-check">Aanmelden</v-chip>
              </v-chip-group>
            </v-row>
          </v-card-text>
        </div>
      </v-window-item>

      <v-window-item :value="3">
        <v-card-text>
          <v-row class="mb-4" justify="space-between">
            <v-col class="text-center">
              <span class="text-h2 font-weight-light" v-text="aantal"></span>
              <span class="subheading font-weight-light pl-3">{{
                aantal === 1 ? "persoon" : "personen"
              }}</span>

              <v-slider
                v-model="aantal"
                track-color="grey"
                min="0"
                disabled
                :max="max"
                readonly
              >
                <template v-slot:prepend>
                  <div class="mx-2 mt-n2">
                    <v-btn
                      x-small
                      fab
                      color="primary"
                      depressed
                      @click.stop="decrement"
                    >
                      <v-icon> mdi-minus </v-icon>
                    </v-btn>
                  </div>

                  <!-- <v-btn icon depressed @click.stop="decrement">
                    <v-icon> mdi-minus </v-icon>
                  </v-btn> -->
                </template>

                <template v-slot:append>
                  <div class="mx-2 mt-n2">
                    <v-btn
                      x-small
                      fab
                      color="primary"
                      depressed
                      @click.stop="increment"
                    >
                      <v-icon> mdi-plus </v-icon>
                    </v-btn>
                  </div>
                  <!-- <v-btn icon depressed @click.stop="increment">
                    <v-icon> mdi-plus </v-icon>
                  </v-btn> -->
                </template>
              </v-slider>
            </v-col>
          </v-row>
        </v-card-text>
      </v-window-item>

      <v-window-item :value="4">
        <div v-if="!errormessage" class="pa-4 text-center">
          <v-fab-transition>
            <v-icon class="mb-4" size="72" color="green"
              >mdi-check-circle-outline</v-icon
            >
          </v-fab-transition>
          <h3 class="text-h6 font-weight-light mb-2">
            Dankjewel voor het {{ aantal > 0 ? "aanmelden" : "afmelden" }}
          </h3>
          <span v-if="aantal > 0" class="text-caption grey--text"
            >Tot op de bruiloft!</span
          >
        </div>
        <div v-else class="pa-4 text-center">
          <v-fab-transition>
            <v-icon class="mb-4" size="72" color="red"
              >mdi-close-circle-outline</v-icon
            >
          </v-fab-transition>
          <h3 class="text-h6 font-weight-light mb-2">
            {{ errormessage }}
          </h3>
        </div>
      </v-window-item>
    </v-window>

    <v-divider></v-divider>

    <v-card-actions>
      <v-btn v-if="step !== previousTexts.length" text @click="previous">
        {{ previousText }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" depressed @click="next" :loading="loading">
        {{ nextText }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>


<script>
export default {
  data: () => ({
    step: 1,
    aantal: 0,
    max: 8,
    loading: false,
    chipGroupSelection: "",
    signupcode: "",
    postalcode: "",
    errormessage: "",
    previousTexts: ["Annuleren", "Annuleren", "Vorige", "Vorige"],
    nextTexts: ["Volgende", "Volgende", "Opslaan", "Sluiten"],
    currentTitles: ["Inloggen", "Je wilt", "Je komt met", "Opslaan is gelukt!"],
  }),

  mounted() {
    document.getElementById("signupField--0")?.focus();
  },

  computed: {
    chipGroupActiveClass() {
      console.log("chipGroupActiveClass", this.chipGroupSelection);
      var cls = "deep-purple accent-4 white--text";

      if (this.chipGroupSelection === 0) {
        cls = "red accent-4 white--text";
      }

      if (this.chipGroupSelection === 1) {
        cls = "green accent-4 white--text";
      }

      console.log(cls);
      return cls;
    },
    chipGroupActiveColor() {
      var cls = "deep-purple";

      if (this.chipGroupSelection === 0) {
        cls = "red";
      }

      if (this.chipGroupSelection === 1) {
        cls = "green";
      }

      return cls;
    },
    previousText() {
      return this.previousTexts[this.step - 1];
    },
    nextText() {
      return this.nextTexts[this.step - 1];
    },
    currentTitle() {
      if (this.errormessage && this.step == this.previousTexts.length) {
        return "Mislukt";
      }
      return this.currentTitles[this.step - 1];
    },
  },

  methods: {
    reset() {
      this.errormessage = "";
      if (this.step > 1) {
        this.step = 2;
      }
    },
    next() {
      this.errormessage = "";

      if (this.step === 1) {
        this.apiVerify();
        return;
      }

      if (this.step === 2) {
        // "Afmelden" selected
        if (this.chipGroupSelection === 0) {
          this.aantal = 0;
          this.apiSignup();
          return;
        }

        // Nothing selected
        if (!this.chipGroupSelection) {
          return;
        }

        // "Aanmelden" selected
        console.log("aanmelden selected:", this.max);
        if (this.max === 1) {
          this.aantal = 1;
          this.apiSignup();
          return;
        }
        
        this.step++;
        return;
      }

      if (this.step === 3) {
        this.apiSignup();
        return;
      }

      if (this.step == this.previousTexts.length) {
        this.close();
      }
    },
    apiVerify() {
      var data = new FormData();
      data.append("signupcode", this.signupcode);
      data.append("postalcode", this.postalcode);

      console.log(data);

      const _this = this;

      this.loading = true;

      setTimeout(() => {
        fetch("/api/verify", {
          method: "POST",
          body: data,
        })
          .then((res) => res.json())
          .then(function (data) {
            if (data.error) {
              _this.errormessage = data.error.message;
            } else {
              _this.errormessage = "";
              _this.aantal = data.numaccepted;
              _this.max = data.invitees;
              _this.step++;
            }
            _this.loading = false;
          });
      }, 250 + Math.random() * 2000);
    },
    apiSignup() {
      var data = new FormData();
      data.append("signupcode", this.signupcode);
      data.append("postalcode", this.postalcode);
      data.append("numaccepted", this.aantal);

      const _this = this;

      this.loading = true;

      setTimeout(() => {
        fetch("/api/signup", {
          method: "POST",
          body: data,
        })
          .then((res) => res.json())
          .then(function (data) {
            if (data.error) {
              _this.errormessage = data.error.message;
            } else {
              _this.errormessage = "";
            }

            _this.step = _this.previousTexts.length;
            _this.loading = false;
          });
      }, 250 + Math.random() * 2000);
    },
    previous() {
      this.errormessage = "";

      switch (this.step) {
        case 1:
        case 2:
          this.close();
          break;
        default:
          this.step--;
      }
    },
    close() {
      this.reset();
      this.$emit("close");
    },
    decrement() {
      this.aantal--;
    },
    increment() {
      this.aantal++;
    },
    uppercaseInputSignup(e) {
      let newValue = e.replace(/[\d]*/g, "").toUpperCase();

      if (newValue != e) {
        this.$refs.otpSignup.$refs.input.value = newValue;
        this.$refs.otpSignup.internalValue = newValue;
      }

      if (newValue.length == 4) {
        setTimeout(() => {
          this.$refs.otpPostal.$refs.input.focus();
        }, 50);
      }
    },
    uppercaseInputPostal(e) {
      // this.$refs.otpPostal.otp = e.toUpperCase().split("");

      let newValue = e.replace(/[^\d]*/g, "");

      if (newValue != e) {
        this.$refs.otpPostal.internalValue = newValue;
      }

      if (this.postalcode.length == 4) {
        this.next();
      }
    },
    finishInputSignup(e) {
      console.log("finish", e);
      document.getElementById("postalField--0").focus();
    },
  },
};
</script>
