<template>
  <v-dialog
    v-model="opened"
    transition="dialog-bottom-transition"
    max-width="500"
    @click:outside="close"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-row justify="center" class="pb-8">
        <v-btn color="primary" v-bind="attrs" v-on="on" @click="open"
          ><v-icon class="mr-2">mdi-calendar</v-icon> Aanmelden</v-btn
        >
      </v-row>
    </template>
    <template v-slot:default>
      <register-form ref="form" @close="close" />
    </template>
  </v-dialog>
</template>


<script>
import RegisterForm from "./RegisterForm.vue";

export default {
  components: { RegisterForm },
  data: () => ({
    dialog: false,
  }),

  computed: {
    opened() {
      return this.$route.name === "signup";
    }
  },

  methods: {
    open() {
      this.$refs.form?.reset();
      this.$router.push({ name: 'signup'});
    },
    close() {
        // go back by one record, the same as history.back()
        this.$router.go(-1);
    },

    // onBeforeUnload(e) {
    //   console.log("onBeforeUnload");
    //   if (this.dialog) {
    //     // Cancel the event
    //     e.preventDefault();

    //     // Chrome requires returnValue to be set
    //     e.returnValue = "";

    //     // Close the dialog instead
    //     this.dialog = false;
    //   }
    // },
  },

  // mounted() {
  //   document.addEventListener("popstate", this.onBeforeUnload);
  //   document.addEventListener("backbutton", this.onBeforeUnload);
  //   document.addEventListener("beforeunload", this.onBeforeUnload);
  // },
  // beforeDestroy() {
  //   document.removeEventListener("popstate", this.onBeforeUnload);
  //   document.removeEventListener("backbutton", this.onBeforeUnload);
  //   document.removeEventListener("beforeunload", this.onBeforeUnload);
  // },
};
</script>
